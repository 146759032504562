import { authPageReducers } from '../auth.page/store';
import { couriersPageReducers } from '../cabinet.page/couriers.page/store';
import { registriesReducers } from '../cabinet.page/registries.page/store';
import { cabinetReducers } from '../cabinet.page/store';

export const reducers = {
  // ...authPageReducers,
  ...couriersPageReducers,
  ...registriesReducers,
  ...cabinetReducers,
};
